import React from 'react'
import styled from 'styled-components'
import { color } from 'const/colors'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  width: auto;

  ${({ currentPath }) =>
    currentPath && currentPath.includes('/careers/listing')
      ? `background-color: ${color.primary.o100}`
      : `background-color: ${color.white.o100}`};
`

const JobWrapper = ({ currentPath, children }) => {
  return (
    <>
      <Wrapper currentPath={currentPath}>{children}</Wrapper>
    </>
  )
}

JobWrapper.propTypes = {
  currentPath: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default JobWrapper
