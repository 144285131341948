const primary = '#F93B51'
const secondary = 'rgba(105,66,127,0.51)'
const white = '#FFF'
const black = '#391D3e'
const purple = '#543D7B'
const pink = '#F94480'
const grey = '#F2F2F2'
const lightGrey = '#FAFAFA'

const darkPurple = 'rgba(97, 67, 133, 1)'
const blue = 'rgba(81, 99, 149, 1)'

export {
  primary,
  secondary,
  purple,
  pink,
  grey,
  lightGrey,
  white,
  darkPurple,
  blue,
  black,
}

export const color = {
  primary: {
    o25: 'rgba(249, 69, 128, 0.25)',
    o50: 'rgba(249, 69, 128, 0.5)',
    o100: 'rgba(249, 69, 128, 1)',
    o150: '#ED1164',
  },
  black: {
    o5: 'rgba(0, 0, 0, 0.05)',
    o25: 'rgba(0, 0, 0, 0.25)',
    o50: 'rgba(0, 0, 0, 0.5)',
    o100: '#391D3e',
  },
  white: {
    o25: 'rgba(255, 255, 255, 0.25)',
    o50: 'rgba(255, 255, 255, 0.5)',
    o75: 'rgba(255, 255, 255, 0.75)',
    o100: 'rgba(255, 255, 255, 1)',
  },
  header1: 'rgba(130, 28, 90, 1)',
  header2: '#ED1164',
  hotPink: {
    o100: '#821C5A',
  },
  warmPink: {
    o100: '#d43a6c',
  },
  blue: {
    o100: '#001aff',
  },
  green: {
    o100: '#1de9b6',
  },
  purple: {
    o100: '#8f67ff',
  },
  magenta: {
    o100: 'rgba(177, 61, 242, 1)',
  },
  transparent: 'rgba(0, 0, 0, 0)',
}
