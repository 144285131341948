module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/JobWrapper/index.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-PQWSR8","cookieName":"marketing-cookie-allowed","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["CeraPro"],"urls":["/fonts/fonts.css"]}},
    }]
