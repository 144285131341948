// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-job-listing-js": () => import("/opt/build/repo/src/templates/job-listing.js" /* webpackChunkName: "component---src-templates-job-listing-js" */),
  "component---src-templates-work-details-index-js": () => import("/opt/build/repo/src/templates/work-details/index.js" /* webpackChunkName: "component---src-templates-work-details-index-js" */),
  "component---src-templates-service-index-js": () => import("/opt/build/repo/src/templates/service/index.js" /* webpackChunkName: "component---src-templates-service-index-js" */),
  "component---src-templates-partner-index-js": () => import("/opt/build/repo/src/templates/partner/index.js" /* webpackChunkName: "component---src-templates-partner-index-js" */),
  "component---src-templates-blog-index-js": () => import("/opt/build/repo/src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("/opt/build/repo/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-how-we-work-js": () => import("/opt/build/repo/src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-js": () => import("/opt/build/repo/src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-team-js": () => import("/opt/build/repo/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-what-we-do-js": () => import("/opt/build/repo/src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-work-js": () => import("/opt/build/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

